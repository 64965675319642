<template>
  <div v-if="loaded" class="print-box">
    <div class="print-left" :style="{ height: `${this.$util.getViewHeight() - 162}px` }">
      <div id="print-content" class="print-content">
        <!-- title -->
        <div class="title-box">
          <h1 class="title-main">{{ detailData.pact_bill.saler_company_name }}</h1>
          <h2 class="title-sub">购销合同</h2>
        </div>

        <!-- title 暂未使用 -->
        <!-- <div class="print-img">
          <img src="https://gcvip168.com/wx/pc/print-contract.png" width="100%" />
        </div> -->

        <!-- formData1 -->
        <div class="form-box">
          <div class="form-list" v-for="(itemSt, indexSt) in formData1" :key="indexSt">
            <div
              :class="{ 'form-item': true, 'form-st': !indexNd }"
              v-for="(itemNd, indexNd) in itemSt"
              :key="indexNd"
            >
              <div class="form-key">{{ itemNd.key }}{{ itemNd.key && '：' }}</div>
              <div class="form-val" v-if="itemNd.val">
                <span v-if="itemNd.val === 'pact_time'">
                  {{ detailData.pact_bill[itemNd.val].slice(0, 10) || '&nbsp;' }}
                </span>
                <span v-else>
                  {{ detailData.pact_bill[itemNd.val] || '&nbsp;' }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- tableData -->
        <div class="table-box">
          <div class="f-bold">
            兹经甲乙协商同意，出需方(乙方)购进，供方(甲方)售出下列货物，并就本次交易达成如下合同，共同遵守执行
          </div>
          <PrintTable :data="detailData.stock_list" />
        </div>

        <!-- formData2 -->
        <div class="form-box l-h-2">
          <div class="form-list flex-column">
            <div
              class="form-item"
              style="align-items: flex-start"
              v-for="(itemSt, indexSt) in formData2"
              :key="indexSt"
            >
              <div class="form-key f-bold" style="width: 120px; padding: 2px 0">
                {{ itemSt.key }}{{ itemSt.key && '：' }}
              </div>
              <div class="form-val" style="border-bottom: none" v-if="itemSt.val">
                {{ detailData.pact_bill[itemSt.val] || '&nbsp;' }}
                <span v-if="indexSt === 2">
                  (是否含运：{{ detailData.pact_bill.has_freight ? '是' : '否' }})
                </span>
                <span v-if="indexSt === 3">
                  (是否含税：{{ detailData.pact_bill.has_tax ? `含13%增值税发票` : '否' }})
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- formData3 -->
        <div class="form-box">
          <img
            class="seal-img"
            v-if="detailData.pact_bill.yin_zhang_img && detailData.pact_bill.status > 1"
            :src="detailData.pact_bill.yin_zhang_img"
          />
          <div class="form-list" v-for="(itemSt, indexSt) in formData3" :key="indexSt">
            <div v-if="indexSt === 0" class="f-s-12 f-bold m-b-10">甲方代表签名盖章</div>
            <div v-if="indexSt === 1" class="f-s-12 f-bold m-b-10">乙方代表签名盖章</div>
            <div class="form-item" v-for="(itemNd, indexNd) in itemSt" :key="indexNd">
              <div class="form-key">{{ itemNd.key }}{{ itemNd.key && '：' }}</div>
              <div class="form-val" v-if="itemNd.val">
                {{ detailData.pact_bill[itemNd.val] || '&nbsp;' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="print-right">
      <div class="print-do">
        <div>
          <el-button class="print-btn" v-print="printData" type="primary" size="small">
            打印
          </el-button>
        </div>
        <div v-if="false" class="m-t-10">
          <el-button class="print-btn" type="primary" size="small" @click="canvasToImg">
            生成图片
          </el-button>
        </div>
        <div class="m-t-10">
          <el-button class="print-btn" type="default" size="small" @click="jumpEdit">
            编辑
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintTable from './print-table'
export default {
  components: {
    PrintTable
  },
  data() {
    return {
      loaded: false,
      detailData: {},
      printData: {
        id: 'print-content',
        popTitle: '&nbsp;'
        // preview: true, // 是否开启预览
        // previewTitle: '打印预览', // 预览标题
      },
      formData1: [
        [
          {
            key: '合同编码',
            val: 'bill_code'
          },
          {
            key: '供方(甲方)',
            val: 'saler_company_name'
          },
          {
            key: '甲方联系人',
            val: 'saler'
          },
          {
            key: '甲方手机号',
            val: 'saler_phone'
          },
          {
            key: '甲方地址',
            val: 'saler_address'
          }
        ],
        [
          {
            key: '签约日期',
            val: 'pact_time'
          },
          {
            key: '需方(乙方)',
            val: 'buyer_company_name'
          },
          {
            key: '乙方联系人',
            val: 'buyer'
          },
          {
            key: '乙方手机号',
            val: 'buyer_phone'
          },
          {
            key: '乙方地址',
            val: 'buyer_address'
          }
        ]
      ],
      formData2: [
        {
          key: '一、包装方式',
          val: 'package_type'
        },
        {
          key: '二、合同履行地',
          val: 'pact_action_address'
        },
        {
          key: '三、运输方式',
          val: 'transport'
        },
        {
          key: '四、付款方式',
          val: 'pay'
        },
        {
          key: '五、质量验收标准',
          val: 'quality_check'
        },
        {
          key: '六、违约责任',
          val: 'break_pact'
        },
        {
          key: '七、争议解决',
          val: 'dispute_resolution'
        },
        {
          key: '八、合同生效说明',
          val: 'pact_effect'
        }
      ],
      formData3: [
        [
          {
            key: '名称',
            val: 'saler_company_name'
          },
          {
            key: '地址',
            val: 'saler_address'
          },
          {
            key: '银行账号',
            val: 'saler_bank_code'
          },
          {
            key: '开户银行',
            val: 'saler_bank_name'
          }
        ],
        [
          {
            key: '名称',
            val: 'buyer_company_name'
          },
          {
            key: '地址',
            val: 'buyer_address'
          },
          {
            key: '银行账号',
            val: 'buyer_bank_code'
          },
          {
            key: '开户银行',
            val: 'buyer_bank_name'
          }
        ]
      ]
    }
  },
  computed: {
    billId() {
      return this.$route.query.id
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    // 获取详情数据
    getDetailData() {
      this.loaded = false
      this.$api({
        method: 'post',
        url: '/admin/gcvip/pack/detail',
        params: {
          id: this.billId
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.detailData = this.$util.deepCopy(res.data)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loaded = true
        })
    },
    // 跳转到编辑
    jumpEdit() {
      this.$router.push(`/sale/contract/detail?id=${this.billId}`)
    },
    // canvas生成图片
    canvasToImg() {
      let element = document.getElementById('print-content')
      let body = document.body
      this.$util.canvasToImg(element, body)
    }
  }
}
</script>

<style lang="less" scoped>
// print
.print-box {
  display: flex;
  background: #f5f7fa;
  .print-left {
    margin: 10px;
    padding: 20px;
    width: 712px;
    background: #fff;
    overflow-y: auto;
    .scroll-bar();
  }
  .print-right {
    margin: 10px 10px 10px 0;
    padding: 10px;
    width: 200px;
    background: #fff;
    .print-btn {
      width: 100%;
    }
    .print-do {
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;
      font-size: 14px;
      background: #f8f8f8;
    }
  }
}

// content
.print-content {
  font-size: 12px;
  line-height: 1.5;
  font-family: 'Arial,Microsoft YaHei,SimHei,SimSun';
  overflow: hidden;
}

// title
.title-box {
  margin: 10px 0 30px 0;
  text-align: center;
}
.title-main {
  margin: 0;
  font-size: 20px;
}
.title-sub {
  position: relative;
  margin: 10px 0 0 0;
  font-size: 20px;
}

// .print-img {
//   width: 100%;
//   height: 148px;
//   overflow: hidden;
// }

// form
.form-box {
  position: relative;
  display: flex;
  margin-top: 20px;
}
.seal-img {
  position: absolute;
  bottom: 10px;
  left: 110px;
}
.form-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  & ~ .form-list {
    margin-left: 10px;
  }
  .form-item {
    flex: 1;
    display: flex;
    align-items: center;
    &.form-st {
      font-weight: bold;
    }
    .form-key {
      border-bottom: 1px solid #fff;
    }
    .form-val {
      flex: 1;
      padding: 2px 0;
      border-bottom: 1px solid #000;
    }
  }
}

// table
.table-box {
  margin: 20px 0;
}

// prompt
</style>
